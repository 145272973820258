import { Component, OnInit } from "@angular/core";
import { FooterComponent } from "./footer/footer.component";
import { Subscription } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { LayoutService } from "../services/layout.service";

import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-admin-panel",
  templateUrl: "./admin-panel.component.html",
  styleUrls: ["./admin-panel.component.scss"],
})
export class AdminPanelComponent implements OnInit {
  entryComponent = FooterComponent;
  pageTitle: any;
  subscription: Subscription;
  constructor(
    private location: Location,
    private cookieService: CookieService,
    private layoutService: LayoutService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let child = this.activatedRoute.firstChild;
        this.pageTitle = child.snapshot.data["breadcrumb"];

        // for eg: utilities
        if (!this.pageTitle) {
          this.pageTitle = this.layoutService.pageTitle;
        }
      }
    });
  }

  ngOnInit() {}

  logout(): void {
    this.cookieService.deleteAll();
    this.localStorageService.clearLocalStorage();
    this.router.navigate(["/login"]);
  }

  goBack(): void {
    this.location.back();
  }
}
