import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";

import * as $ from "jquery";
import { environment } from "@env/environment";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { HelperService } from "@app/shared/services/helper/helper.service";

@Component({
  selector: "crm-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit {
  defaultImagePath = environment.defaultImagePath;

  userInfo: any;
  imageUrl: any;
  userName: any;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.getUserInfo();
  }

  navigateToDashboard() {
    this.router.navigate(["/dashboard"]);
  }

  getUserInfo() {
    this.userName = JSON.parse(
      this.localStorageService.getLocalStorageItem("username")
    );
    this.userName = JSON.parse(this.userName);
  }

  logOut(): void {}
}
