import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClientService } from "@app/core/services/http-client/http-client.service";
import { CookieService } from "ngx-cookie-service";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  apiUrl = environment.baseIP + environment.apiPrefix;

  constructor(
    private httpClient: HttpClientService,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService
  ) {}

  login(body) {
    return this.httpClient.post(`${this.apiUrl}user/login`, body);
  }

  getTokenFromCookie() {
    return this.cookieService.get("access_token");
  }

  setUserInfoOnStorage(userInfo) {
    this.localStorageService.setLocalStorageItem("user_info", userInfo);
  }

  getUserInfoFromStorage() {
    return this.localStorageService.getLocalStorageItem("user_info");
  }
}
