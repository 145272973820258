export const IconConst = {
  DASHBOARD: "fas fa-fw fa-tachometer-alt",
  COMPANY: "fa fa-building",
  LEADS: "fa fa-bullhorn",
  CONTACT: "fa fa-user",
  BID_INFORMATION: "fa fa-info",
  GROUPS: "fa fa-group",
  MERGE_FIELDS: "fa fa-code-fork",
  EMAIL_TEMPLATE: "fa fa-envelope",
  SEND_EMAIL: "fa fa-check-square",
  MARKETING: "fa  fa-globe",
  PRODUCT: "fa fa-file-photo-o",
  PASSWORD: "fa  fa-lock",

  BTN: {
    EDIT: "fa fa-edit",
    DELETE: "fa  fa-trash",
  },

  EXPORT_TO_PDF: "fas fa-file-pdf",
  EXPORT_TO_CSV: "fas fa-file-csv",
  PRINT: "fas fa-print",
  EMAIL: "fas fa-envelope",
};
